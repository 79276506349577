import {paymentStatusDisplay} from "../../lib/utils/promise";
import {Button, Modal, ModalBody} from "react-bootstrap";
import {formattedDate} from "../../lib/utils/fixdate";
import {useState} from "react";


const SinglePayment = (props) => {
    const {payment, manual, useManual, otp, promiseDeleted, isAffected} = props;
    const [showManual, setShowManual] = useState(false);

    const performManual = () => {
        setShowManual(false);
        manual(payment.id);
    }

    return <tr key={payment.id} className={isAffected ? "affected-payment": ""}>
        <td>{ otp === false ? formattedDate(payment.scheduledPaymentDate) : "OTP"}</td>
        <td>${payment.amount}</td>
        <td>${payment.actualAmount}</td>
        <td>{paymentStatusDisplay(payment.status)}</td>
        <td>
            {payment.status === "scheduled" && useManual &&
                <Button
                    className={'btn btn-primary'}
                    onClick={() => setShowManual(true)}
                    disabled={payment.isDeleted === true || promiseDeleted}
                >
                    Allocate Check Or Wire
                </Button>
            }
        </td>
        <Modal show={showManual} onHide={() => setShowManual(false)}>
            <Modal.Header closeButton>
                Allocate Check or Wire Payment
            </Modal.Header>
            <ModalBody>
                Are you sure you want to allocate a check or wire for {payment.actualAmount}?
            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowManual(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => performManual()} disabled={promiseDeleted || payment.status === "deleted" && !useManual}>
                    Allocate
                </Button>
            </Modal.Footer>
        </Modal>
    </tr>
}

export default SinglePayment;