import {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";

const PayPalModal = ({show, handleClose, customer, customerCallback}) => {

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.message === "PAYMENT_SUCCESS") {
                customerCallback()
                handleClose()
            }
        })
    }, []);

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <iframe src={`/paypal?id=${customer.id}`} id={"paypal-iframe"} style={{
                width: "100%",
                minHeight: "300px",
            }}/>
        </Modal.Body>
      </Modal>
}

export default PayPalModal