import {useState, useCallback, useEffect} from "react";
import {makeRequest} from "../api/request";

// localStorage keys
const AUTH_TOKEN_KEY = "authToken";
const USER_KEY = "tklUser";

export const createRegularUser = (userData) => {
    const {id, firstName, lastName, email, isStaff, isSuperuser} = userData || {};
    if (!id) {return {}}
    let user = {
        id: id || "",
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        isStaff: isStaff || false,
        isSuperuser: isSuperuser || false,
    }
    user.displayName = user.lastName ? [user.firstName, user.lastName].join(" ") : user.email
    return user
}

export const createUser = (userData) => {
    const {id, firstName, lastName, email, isStaff, isSuperuser} = userData || {};
    if (!id) {return {}}
    let user = {
        id: id || "",
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        isStaff: isStaff || false,
        isSuperuser: isSuperuser || false,
    }
    user.displayName = user.lastName ? [user.firstName, user.lastName].join(" ") : user.email
    if (!user.isSuperuser && !user.isStaff) return {}
    return user
}

const getUser = () => {
    let storageUser = localStorage.getItem(USER_KEY)
    if (storageUser) {
        return JSON.parse(storageUser);
    }
}

const setUserInStorage = (rawUser) => {
    localStorage.setItem(USER_KEY, JSON.stringify(createUser(rawUser)))
}

export const useAuth = () => {
    const [token, setToken] = useState(localStorage.getItem(AUTH_TOKEN_KEY) || "");
    const [user, setUser] = useState(getUser());

    const login = useCallback((authToken) => {
        localStorage.setItem(AUTH_TOKEN_KEY, authToken);
        setToken(authToken);
        setTimeout(() => {
            window.location.reload(false);
        }, 250)
    }, []);

    const fetchUser = useCallback(() => {
        if (!token) return;
        makeRequest("/users/me/", 'GET', null, token).then(response => {
            let user = createUser(response);
            if (!user.isSuperuser && !user.isStaff) {
                setUser({});
                logout();
            } else {
                setUser(user);
            }
        }).catch(err => console.log("err", err));
    }, [])

    useEffect(() => {
        setUserInStorage(user);
    }, [user])

    const logout = useCallback(() => {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        window.location.reload(false);
    }, [])

    useEffect(() => {
        fetchUser();
    }, [login])

    return {login, logout, token, user, fetchUser}
}