import {createContext, useContext, useEffect, useState} from "react";
// import
import {useAuth} from "../hooks/auth";

// can later add stuff like user role here
export const AuthContext = createContext({
    token: null,
    user: {
        id: "",
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        isStaff: false,
        isSuperuser: false,
    },
    login: () => {},
    logout: () => {},
    fetchUser: () => {},
    isAuthenticated: false,
})

export function ProvideAuth({ children }) {
  const {login, logout, token, user, fetchUser} = useAuth();
  const isAuthenticated = !!token && user && Object.keys(user).length !== 0;

  return <AuthContext.Provider value={{
      token, user, login, logout, isAuthenticated, fetchUser
    }}>{children}
  </AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);