import { DateTime } from 'luxon';

export const formattedDate = (rawDate) => {
    if (rawDate && rawDate.includes("T")) {
        // FIXME: process
        let date = DateTime.fromISO(rawDate);//.toUTC({zone: localTimezone()});
        return date.toFormat('y-MM-dd HH:mm ZZZZ')
    }
    if (rawDate) return rawDate.split('T')[0]
    return rawDate
}