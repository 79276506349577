import {Badge} from "react-bootstrap";

export const statusDisplay = (status) => {
    let className = "secondary", badgeText = "In Progress";
    switch (status) {
        case "deleted":
            className = "danger";
            badgeText = "Deleted";
            break;
        case "in_progress":
            className = "primary";
            badgeText = "Established";
            break;
        case "paid_off":
            className = "success"
            badgeText = "Paid Off"
            break;
        case "completed":
            className = "success";
            badgeText = "Completed";
            break;
        case "cancelled":
            className = "danger"
            badgeText = "Cancelled"
            break;
        default:
            break;
    }

    return <Badge bg={className}>{badgeText}</Badge>
}

// FIXME: need to display deleted payments somehow
export const paymentStatusDisplay = (status) => {
    let className = "secondary", badgeText = "In Progress";
    switch (status) {
        case "in_progress":
            className = "primary"
            break;
        case "scheduled":
            className = "secondary"
            badgeText = "Scheduled"
            break;
        case "complete":
        case "completed":
            className = "success"
            badgeText = "Completed"
            break;
        case "missed":
        case "failed":
            className = "danger"
            badgeText = "Failed"
            break;
        case "cancelled":
            className = "warning"
            badgeText = "Cancelled"
            break;
        default:
            break;
    }

    return <Badge bg={className}>{badgeText}</Badge>
}

export const displayPromiseType = (promiseType) => {
    switch (promiseType) {
        case "OTP":
            return "One Time Payment";
        case "PP":
            return "Payment Plan"
        default:
            return "Unknown"
    }
}

export const displayTermType = (termType) => {
    switch (termType) {
        case "D":
            return "days"
        case "W":
            return "weeks"
        case "BW":
            return "weeks (biweekly)"
        case "M":
            return "months"
        case "Y":
            return "years"
        default:
            return ""
    }
}