import {useEffect, useState} from "react";
import {makeRequest} from "../../lib/api/request";
import LoadingSpinner from "../../components/LoadingSpinner";
import TablePagination from "../../components/Pagination";
import CustomerPromises from "../CustomerProfile/CustomerPromises";
import {DropdownButton, Dropdown} from "react-bootstrap";
import SearchDropdown from "../../components/SearchDropdown";
import {DebounceInput} from "react-debounce-input";
import {useNavigate} from "react-router-dom";
import Select from 'react-select'

import './style.scss';

const PromiseList = () => {
    let navigate = useNavigate();

    const [promises, setPromises] = useState([]);

    const [loaded, setLoaded] = useState(false);

    // const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pagesNumber, setPagesNumber] = useState(0);

    // filters
    const [status, setStatus] = useState("all");
    const [type, setType] = useState("all");
    const [payment, setPayment] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [lookupDelinquent, setLookupDelinquent] = useState(false);
    const [lookupOpenEnded, setLookupOpenEnded] = useState(false);

    const [selectedSearchType, setSelectedSearchType] = useState({ value: 'data_id', label: 'Data ID' });

    const SearchOptions = [
      { value: 'id', label: 'Id' },
      { value: 'data_id', label: 'Data Id' },
      { value: 'owner__email', label: 'Email' },
      { value: 'owner__first_name', label: 'First Name' },
      { value: 'owner__last_name', label: 'Last Name' }
    ];

    const selectChange = (e) => {
      setSelectedSearchType(e);
    }

    useEffect(() => {
        loadPromises();
    }, [page, status, type, payment, searchValue, lookupDelinquent, lookupOpenEnded])

    const getUrl = () => {

      let url;

      if (selectedSearchType?.value) {
        url = `promises/?page=${page}&search_field=${selectedSearchType?.value}`;
      } else {
        url = `promises/?page=${page}`;
      }

        if (status && status !== "all") url = `${url}&status=${status}`;
        if (type && type !== "all") url = `${url}&promise_type=${type}`;
        if (payment && payment !== "all") url = `${url}&payment=${payment}`;
        if (searchValue && searchValue !== "") url = `${url}&search=${encodeURIComponent(searchValue)}`;
        if (lookupDelinquent) url =  `${url}&is_delinquent=true`;
        if (lookupOpenEnded) url =  `${url}&is_open_ended=true`;

        return url
    }

    const processLookupDelinquentToggle = (newValue) => {
        switch (newValue) {
            case "all":
                setLookupDelinquent(false);
                break;
            case "delinquent":
                setLookupDelinquent(true);
                break;
        }
    }

    const processLookupOpenEndedToggle = (newValue) => {
        switch (newValue) {
            case "all":
                setLookupOpenEnded(false);
                break;
            case "is_open_ended":
                setLookupOpenEnded(true);
                break;
        }
    }

    const loadPromises = () => {
        setLoaded(false)
        makeRequest(getUrl(), "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            if (response.status === 400 || response.detail === "Invalid page.") {
                setCount(0)
                setPagesNumber(1)
                setPromises([])
                setPage(1)
                setLoaded(true)
                return
            }
            setCount(response.count);
            setPagesNumber(response.pages);
            setPromises(response.results);
            setLoaded(true);
        })
    }

    return (<div>
        <div className={'d-flex flex-row justify-content-start my-2'}>
            <DebounceInput
              placeholder={"Search"}
              minLength={2}
              debounceTimeout={300}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              autoFocus={searchValue !== ""}
            />
            <Select
              className="select-search-container"
              id="search-dropdown"
              isClearable="true"
              placeholder="Select"
              defaultValue={selectedSearchType}
              options={SearchOptions}
              onChange={selectChange}
            />
            <SearchDropdown
                options={{
                    'all': "All Statuses",
                    'in_progress': "Established",
                    'completed': "Completed",
                    'paid_off': "Paid Off",
                    'deleted': "Deleted",
                }}
                callback={setStatus}
            />
            <SearchDropdown
                options={{
                    'all': "All Types",
                    'OTP': "OTP",
                    'PP': "Payment Plan",
                }}
                callback={setType}
            />
            {/* TODO: add is_delinquent search */}
            <SearchDropdown
                options={{
                    "all": "Including delinquent",
                    "delinquent": "Only delinquent",
                }}
                callback={processLookupDelinquentToggle}
            />
            <SearchDropdown
                options={{
                    "all": "Including open-ended",
                    "is_open_ended": "Only open-ended",
                }}
                callback={processLookupOpenEndedToggle}
            />
            {/* TODO: re-enable once payment methods for individual Promise have been implemented*/}
            {/*<SearchDropdown*/}
            {/*    options={{*/}
            {/*        'all': "All Payment Methods",*/}
            {/*        'card': "Card",*/}
            {/*        'ach': "ACH",*/}
            {/*        'manual': "Mail",*/}
            {/*    }}*/}
            {/*    callback={setPayment}*/}
            {/*/>*/}

        </div>
        { loaded ? <CustomerPromises promises={promises} /> : <LoadingSpinner /> }
        <TablePagination
            page={page}
            changePageCallback={setPage}
            count={count}
            numberOfPages={pagesNumber}
        />
    </div>)
}

export default PromiseList;