import UserProfileDetails from "../../components/UserProfileDetails";
import {useAuthContext} from "../../lib/context/auth";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import {makeRequest} from "../../lib/api/request";
import {createUser} from "../../lib/hooks/auth";


const UserProfile = () => {
    let { id } = useParams();
    const {user} = useAuthContext();
    const [currentUser, setCurrentUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoaded(false);
        if (id === undefined) {
            setCurrentUser(user);
            setLoaded(true);
        } else {
            makeRequest(`/users/${id}/`, 'GET').then(response => {
                if (response.detail) {
                    setError(true)
                } else {
                    setCurrentUser(createUser(response))
                }
                setLoaded(true)
            })
        }
    }, [id, user])

    if (currentUser === null && !loaded) {
        return <LoadingSpinner />
    }

    if (error) {
        return <div>Agent not found.</div>
    }

    return (
        <div>
            <h1>Profile Settings</h1>
            <UserProfileDetails currentUser={currentUser} />
            {/*  TODO: add more data to user profile  */}
        </div>
    )
}

export default UserProfile;