import {Dropdown, DropdownButton} from "react-bootstrap";
import {SystemArrowDownIcon24} from "../icons/SystemIcons";
import {useEffect, useState} from "react";
import './index.css';

const SearchDropdown = (props) => {
    const {options, callback} = props;
    const [currentOption, setCurrentOption] = useState('all');

    const title = (<>{currentOption ? options[currentOption] : options['all']} <SystemArrowDownIcon24 /></>)

    useEffect(() => {
        callback(currentOption)
    }, [currentOption])

    return <DropdownButton
        className={'mx-2 search-field body-reg'}
        style={{fontWeight: "400 !important"}}
        title={title}
    >
        {
            Object.entries(options).map(([searchKey,value]) => (
                <Dropdown.Item key={`search-${searchKey}`} onClick={() => setCurrentOption(searchKey)}>
                    {searchKey === 'all' ? "All" : `${value}`}
                </Dropdown.Item>
            ))
        }
    </DropdownButton>
}
export default SearchDropdown;