import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {ProvideAuth, useAuthContext} from "./lib/context/auth";
import {useRoutes} from "./routes";
import {BrowserRouter as Router} from 'react-router-dom';
import {TenantContextProvider} from "./lib/context/tenantContext";

import './App.css';

function App() {

  const WrappedRouter = () => {
    const {isAuthenticated} = useAuthContext();
    const routes = useRoutes(isAuthenticated);

    return <Router>
      <div className="app">{routes}</div>
    </Router>
  }

  return (
    <TenantContextProvider>
      <ProvideAuth>
        <WrappedRouter />
      </ProvideAuth>
    </TenantContextProvider>
  );
}

export default App;
