function getBaseUrl() {
    const origin = window.location.origin;
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    if (process.env.REACT_APP_WORKING_ENV === 'develop') {
        const devPort = process.env.REACT_APP_API_PORT;

        return `${protocol}//api.${hostname}:${devPort}`;
    }

    return `${protocol}//api.${hostname}`;
}


const BASE_URL = getBaseUrl()


export const authToken = () => {
    let tokenInStorage = localStorage.getItem("authToken");
    return tokenInStorage ? `Bearer ${tokenInStorage}` : "";
}

// you should theoretically be able to pass in token to make requests as a different user
export const makeRequest = async (url, method = 'GET', body = {}, token= "") => {
    if (!url.startsWith('/')) url = `/${url}`
    const fullUrl = `${BASE_URL}/api/v1${url}`;
    const response = await fetch(fullUrl, {
        method: method,
        mode: 'cors',
        headers: new Headers({
            "Authorization": token ? `Bearer ${token}` : authToken(),
            "Content-Type": "application/json",
        }),
        body: ["GET", "HEAD"].includes(method) ? null : JSON.stringify(body),
    })
    if (response.status === 500) return response;

    let data = await response.json()

    if (response.status >= 400) {
        if (response.status === 403) {
            return 403
        }
        if (!data) throw new Error(data.error);
    }
    return data;
}