import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = () => (
    <div>
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
);

export default LoadingSpinner;
