import {Badge} from "react-bootstrap";
import {formattedDate} from "../../lib/utils/fixdate";

const PaymentPromiseHistoryEntry = ({paymentHistory, index}) => {
    const {id, history} = paymentHistory;

    const getChangeType = (changeCode) => {
        switch (changeCode) {
            case "~":
                return "Modification"
            case "-":
                return "Deletion"
            default:
                return "Added"
        }
    }

    const getStatusDisplay = (status) => {
        let badgeText = '';
        let bgColor = "secondary";
        switch (status) {
            case "in_progress":
                badgeText = 'IN PROGRESS';
                bgColor = "secondary"
                break;
            case "scheduled":
                badgeText = 'SCHEDULED';
                bgColor = "secondary"
                break;
            case "missed":
            case "failed":
                badgeText = 'FAILED';
                bgColor = "danger"
                break;
            case "cancelled":
                badgeText = 'CANCELLED';
                bgColor = "warning"
                break;
            case "completed":
                badgeText = 'COMPLETED';
                bgColor = "success"
                break;
            default:
                badgeText = "UNKNOWN";
                break;
        }
        return <Badge bg={bgColor}>{badgeText}</Badge>
    }

    const getChangedField = (rawField) => {
        switch (rawField) {
            case "scheduled_payment_date":
                return "planned payment date"
            case "payment_date":
                return "payment date"
            case "payment_type":
                return "payment type"
            case "payment_method":
                return "payment method"
            case "pn_ref":
                return "repay id"
            case "amount":
                return "initial amount"
            case "actual_amount":
                return "actual amount"
            default:
                return rawField
        }
    }

    return <>
            {[...history].reverse().map((entry, entryIndex) => {
                return <tr key={`${entry.historyId}-${entryIndex}`} style={{backgroundColor: entryIndex > 0 ? "#d3d3d324": ""}}>
                    {/*  TODO: fix date display */}
                    <td>{entryIndex === 0 && entry.id}</td>
                    <td>{entry.isExtra && "Yes" }</td>
                    <td>{entry.historyId}</td>
                    <td>
                        {new Intl.DateTimeFormat('en-US',
                            {
                                year: 'numeric', month: 'numeric', day: 'numeric',
                                hour: 'numeric', minute: 'numeric', second: 'numeric',
                            }
                        ).format(new Date(entry.historyDate))}</td>
                    <td>{formattedDate(entry.scheduledPaymentDate)}</td>
                    <td>{formattedDate(entry.paymentDate)}</td>
                    <td>{getChangeType(entry.historyType)}</td>
                    <td>{entry.amount}</td>
                    <td>{entry.actualAmount}</td>
                    <td>{getStatusDisplay(entry.status)}</td>
                    <td>{entry.diff.map((changedField, index) => {
                        return <span key={`history-entry-diff-${changedField}`}>{getChangedField(changedField)}{index < entry.diff.length - 1 ? ", " : " "}</span>
                    })
                    }</td>
                </tr>
            })}
    </>
}

export default PaymentPromiseHistoryEntry;