import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import LoginPage from './pages/Login/index';
import NavBar from "./components/navbar";
import UserProfile from "./pages/UserProfile";
import AgentsList from "./pages/AgentsList";
import CustomerList from "./pages/CustomerList";
import CustomerProfile from "./pages/CustomerProfile";
import PromiseList from "./pages/PromiseList";
import PromiseDetails from "./pages/PromiseDetails";
import Error404 from "./pages/Error404";
import Error403 from "./pages/Error403";
import PayPal from "./pages/PayPal";

export const useRoutes = (isAuthenticated) => {
    if (window.location.pathname.match("paypal")) {
        return <Routes>
            <Route path="/paypal" exact element={<PayPal />} />
        </Routes>
    }
    if (isAuthenticated) {
        return (
            <>
                <NavBar />
                <div id={'app-root'}>
                    <Routes>
                        <Route path="/" exact element={<Navigate to="/promises" replace />}/>
                        <Route path="/profile" exact element={<UserProfile />}/>
                        <Route path="/agents" exact element={<AgentsList />}/>
                        <Route path="/agents/:id" exact element={<UserProfile />}/>
                        <Route path="/customers" exact element={<CustomerList />}/>
                        <Route path="/customers/:id" exact element={<CustomerProfile />} />
                        <Route path="/promises" exact element={<PromiseList />}/>
                        <Route path="/promises/:userid/:id" exact element={<PromiseDetails />}/>
                        <Route path="/403" exact element={<Error403/>} />
                        <Route path="*" exact element={<Error404 />} />
                    </Routes>
                </div>
            </>
        );
    } else {
        return (
        <Routes>
            <Route path="*" element={<LoginPage />}/>
        </Routes>);
    }
}