import {useEffect, useState} from "react";
import {Badge, Button, Table} from "react-bootstrap";
import {makeRequest} from "../../lib/api/request";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useNavigate} from "react-router-dom";
import ACHModal from "./ACHModal";
import {TENANTS, useTenantContext} from "../../lib/context/tenantContext";
import PayPalModal from "./PayPalModal";

const BankDetails = (props) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {customer, callback} = props;
    const [errorMessage, setErrorMessage] = useState("");
    const [achOpen, setAchOpen] = useState(false);
    const [payPalOpen, setPayPalOpen] = useState(false);
    const [cards, setCards] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [paypalAccounts, setPayPalAccounts] = useState([]);
    const [manual, setManual] = useState(null);

    const {tenant} = useTenantContext();


    const fetchPMs = () => {
        setLoading(true);
        makeRequest(`/customers/${customer.id}/payment_methods/cards/`).then((response) => {
            console.log('cards', response)
            setCards(response.cards);
        });
        makeRequest(`/customers/${customer.id}/payment_methods/achs/`).then((response) => {
            console.log('achs', response)
            setBankAccounts(response.bankAccounts);
        });
        makeRequest(`/customers/${customer.id}/payment_methods/manual/`).then((response) => {
            console.log('manual', response);
            setManual(response.manualPaymentMethod);
        });

        makeRequest(`/customers/${customer.id}/payment_methods/paypal/`).then((response) => {
            console.log('paypal', response);
            setPayPalAccounts(response.paypalAccounts);
        });
        setLoading(false)
    }

    useEffect(() => {fetchPMs()}, [customer]);

    const addPaymentMethod = (method) => {
        setLoading(true);
        setErrorMessage("");
        makeRequest(`/customers/${customer.id}/payment_methods/add/${method}/`, "POST", {}).then(response => {
            if (response === 403) {
                navigate("/403")
            }
            setLoading(false);
            if (response.url) {
                window.open(response.url);
            } else {
                if (response.error) {
                    setErrorMessage(response.error)
                } else setErrorMessage("Failed connecting to the payment processor service.")
            }
        }).catch(err => {
            console.log('Error making request to repay: ', err)
            setErrorMessage("Error connecting to the Repay service.")
            setTimeout(() => setLoading(false), 500);
        })
    }

    const toggleAchModal = () => setAchOpen(!achOpen);
    const togglePayPalModal = () => setPayPalOpen(!payPalOpen);

    const deletePaymentMethod = (paymentMethodID) => {
        setLoading(true);
        setErrorMessage("");
        makeRequest(`/customers/${customer.id}/payment_methods/${paymentMethodID}/`, "DELETE", {}).then(response => {
            setLoading(false);
            if (response.error) {
                setErrorMessage(response.error)
            }
            callback();
            // else setErrorMessage("Failed connecting to the Repay service.")
        }).catch(err => {
            console.log('Error making request to repay: ', err)
            setErrorMessage("Error connecting to the Repay service.")
            setTimeout(() => setLoading(false), 500);
        })
    }

    const setAsDefault = (paymentMethodID) => {
        setLoading(true);
        setErrorMessage("");
        makeRequest(`/customers/${customer.id}/payment_methods/${paymentMethodID}/set_as_default/`, "POST", {}).then(response => {
            setLoading(false);
            if (response.error) {
                setErrorMessage(response.error)
            }
            callback();
        }).catch(err => {
            console.log('Error making request to repay: ', err)
            setErrorMessage("Error connecting to the Repay service.")
            setTimeout(() => setLoading(false), 500);
        })
    }
    const disabledTenants = [TENANTS.consuegra, TENANTS.tromberg, TENANTS.ncls];

    return (<>
        <div className={'d-flex flex-row justify-start'}>
        </div>
        {errorMessage && <div style={{color: "#dc3545", fontSize: "0.85rem"}}>{errorMessage}</div>}
        {/*{loading && <LoadingSpinner /> }*/}
        <div>
            { manual &&
            <div className={'my-2 mb-3'}>
                <div className={'d-flex flex-row justify-content-between mb-3'}>
                    <div className={'d-flex flex-row align-items-center'}>
                        <h2>Manual</h2>
                        {manual.isDefault && <Badge bg="primary" className={'ml-2'}>DEFAULT</Badge>}
                    </div>
                    <Button className={'mx-1'} onClick={() => setAsDefault("manual")} disabled={loading || disabledTenants.includes(tenant)}>Set as default</Button>
                </div>
            </div>}
            <div className={'my-2'}>
                <div className={'d-flex flex-row justify-content-between mb-3'}>
                    <h2>Cards</h2>
                    <Button className={'mx-1'} onClick={() => addPaymentMethod("card")} disabled={loading || disabledTenants.includes(tenant)}>Add new card</Button>
                </div>
                {cards &&
                <Table>
                    <thead>
                    <tr>
                        <td>Card Number</td>
                        <td>Brand</td>
                        <td>Card Name</td>
                        <td>Expiration Date</td>
                        <td>DataID</td>
                        <td></td>
                        <td>Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                    {cards.map(card => <tr key={card.id}>
                            <td>xxxx-xxxx-xxxx-{card.last4}</td>
                            <td>{card.cardBrand}</td>
                            <td>{card.customerName}</td>
                            <td>{card.validUntil}</td>
                            <td>{card.dataId}</td>
                            <td>{card.isDefault && <Badge bg="primary">DEFAULT</Badge>}</td>
                            <td>
                                <Button disabled>Edit</Button>
                                <Button
                                    className={'btn body-boldmx-2'}
                                    onClick={() => setAsDefault(card.id)}
                                >Set as default</Button>
                                <Button
                                    className={'btn body-bold btn-danger mx-2'}
                                    onClick={() => deletePaymentMethod(card.id)}
                                >Delete</Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                }
            </div>
            <div className={'my-2'}>
                <div className={'d-flex flex-row justify-content-between mb-3'}>
                    <h2>Bank Accounts</h2>
                        <Button className={'mx-1'} onClick={() => setAchOpen(true)} disabled={loading || disabledTenants.includes(tenant)}>
                            Add bank account
                        </Button>

                    <ACHModal show={achOpen} handleClose={toggleAchModal} customer={customer} customerCallback={callback} />
                </div>
                {bankAccounts &&
                <Table>
                    <thead>
                    <tr>
                        <td>Bank Name</td>
                        <td>Last Four</td>
                        <td>Routing Number</td>
                        <td>Account Holder</td>
                        <td>Type</td>
                        <td>DataID</td>
                        <td></td>
                        <td>Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                    {bankAccounts.map(ach => <tr key={ach.id}>
                        <td>{ach.bankName}</td>
                        <td>{ach.last4}</td>
                        <td>{ach.routingNumber}</td>
                        <td>{ach.customerName}</td>
                        <td>{ach.accountType}</td>
                        <td>{ach.dataId}</td>
                        <td>{ach.isDefault && <Badge bg="primary">DEFAULT</Badge>}</td>
                        <td>
                            <Button disabled>Edit</Button>
                            <Button
                                className={'btn body-boldmx-2'}
                                onClick={() => setAsDefault(ach.id)}
                            >Set as default</Button>
                            <Button
                                className={'btn body-bold btn-danger mx-2'}
                                onClick={() => deletePaymentMethod(ach.id)}
                            >Delete</Button>
                        </td>
                    </tr>
                    )}
                    </tbody>
                </Table>
                }
            </div>
            {tenant === TENANTS.investinet && <div className={'my-2'}>
                <div className={'d-flex flex-row justify-content-between mb-3'}>
                    <h2>PayPal</h2>
                        <Button className={'mx-1'} onClick={() => setPayPalOpen(true)} disabled={loading}>
                            Add PayPal account
                        </Button>

                    <PayPalModal show={payPalOpen} handleClose={togglePayPalModal} customer={customer} customerCallback={callback} />
                </div>
                { paypalAccounts && <Table>
                    <thead>
                    <tr>
                        <td>Email</td>
                        <td>Account Holder</td>
                        <td></td>
                        <td>Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                        {paypalAccounts.map((pp) => <tr key={pp.id}>
                            <td>{pp.email}</td>
                            <td>{pp.customerName}</td>
                            <td>{pp.isDefault && <Badge bg="primary">DEFAULT</Badge>}</td>
                            <td>
                                <Button disabled>Edit</Button>
                                <Button
                                    className={'btn body-boldmx-2'}
                                    disabled={loading}
                                    onClick={() => setAsDefault(pp.id)}
                                >Set as default</Button>
                                <Button
                                className={'btn body-bold btn-danger mx-2'}
                                disabled={loading}
                                onClick={() => deletePaymentMethod(pp.id)}
                            >Delete</Button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>}
            </div>}
        </div>
    </>)
}

export default BankDetails;