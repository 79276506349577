import Pagination from 'react-bootstrap/Pagination';
import {SystemArrowLeftIcon16, SystemArrowRightIcon16} from "../icons/SystemIcons";
import './index.scss';

// TODO: finish functionality
const TablePagination = (props) => {
    const {page, count, numberOfPages, changePageCallback} = props;
    const perPage = 10; // don't think there will be a dynamic amount for perPage

    const moveBack = () => {
        if (page > 1) {
            changePageCallback(page - 1)
        }
    }

    const moveForward = () => {
        if (page < numberOfPages) {
            changePageCallback(page + 1)
        }
    }

    const openSelectedPage = (page) => {
      changePageCallback(page);
    }

    const renderPaginationList = () => {
      return (
        <>
          {numberOfPages < 2 && (
            <div className={'pagination-container pagination-item pagination-active body-reg'}>{page}</div>
          )}
          
          {page > 2 && (
            <>
              <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(1)}>{1}</div>
              <Pagination.Ellipsis />
            </>
          )}
          {page > 1 && page < numberOfPages && (
            <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(page - 1)}>{page - 1}</div>
          )}
          {page < numberOfPages && (
            <>
              <div className={'pagination-container pagination-item pagination-active body-reg'} onClick={() => openSelectedPage(page)}>{page}</div>
              <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(page +1)}>{page + 1}</div>
              {page < numberOfPages -1 && (
                <>
                  <Pagination.Ellipsis />
                  <div className={'pagination-container pagination-item body-reg'} onClick={()=> openSelectedPage(numberOfPages)}>{numberOfPages}</div>
                </>
              )}
            </>
          )}
          {page === numberOfPages && numberOfPages > 2 && (
            <>
              <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(page - 2)}>{page - 2}</div>
              <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(page - 1)}>{page - 1}</div>
              <div className={'pagination-container pagination-item pagination-active body-reg'} onClick={() => openSelectedPage(page)}>{page}</div>
            </>
          )}
          {page === numberOfPages && numberOfPages === 2 && (
            <>
              <div className={'pagination-container pagination-item body-reg'} onClick={() => openSelectedPage(page - 1)}>{page - 1}</div>
              <div className={'pagination-container pagination-item pagination-active body-reg'} onClick={() => openSelectedPage(page)}>{page}</div>
            </>
          )}
        </>
      )
    }

    return (
        <div className={'d-flex flex-row'}>
            <Pagination>
                <div className={`pagination-container icon-cursor ${page === 1 ? " disabled" : ''}`} onClick={() => moveBack()}>
                  <SystemArrowLeftIcon16 />
                </div>
                {renderPaginationList()}
                <div className={`pagination-container icon-cursor ${page === numberOfPages ? " disabled" : ''}`} onClick={() => moveForward()}>
                  <SystemArrowRightIcon16 />
                </div>
            </Pagination>
        </div>
    );
}

export default TablePagination;