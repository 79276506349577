import {useEffect, useState} from "react";
import {makeRequest} from "../../lib/api/request";
import {createUser} from "../../lib/hooks/auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../lib/context/auth";
import {Button, Tab, Tabs} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {getState} from "../../lib/utils/states";
import BankDetails from "./BankDetails";
import CustomerPromises from "./CustomerPromises";
import TablePagination from "../../components/Pagination";

const CustomerProfile = () => {
    let navigate = useNavigate();
    let { id } = useParams();
    const {user} = useAuthContext();
    const [customer, setCustomer] = useState(null);
    const [customerPromises, setCustomerPromises] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false)

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pagesNumber, setPagesNumber] = useState(0);

    const loadCustomer = (soft) => {
      if (!soft) setLoaded(false);
      makeRequest(`/customers/${id}/`, 'GET').then(response => {
        if (response === 403) {
          navigate("/403")
        }
        if (response.detail) {
          setError(true)
        } else {
          console.log('customer details response:', response)
          setCustomer(response)
        }
      })

      setLoaded(true)
    }

    const getLoans = (soft) => {
      if (!soft) setLoaded(false);
      makeRequest(`/customers/${id}/loans/?page=${page}`, 'GET').then(response => {
        if (response === 403) {
            navigate("/403")
        }
        setCustomerPromises(response.promises);
        setPagesNumber(response.pages);
    })
    }

    useEffect(() => {
      getLoans(false)  
    }, [page])

    useEffect(() => {
        setLoaded(false);
        if (id === undefined) {
            setCustomer(null);
            setLoaded(true);
        } else {
            loadCustomer(false);
        }
    }, [id])

    useEffect(() => {
        if (customer) {
            setDisplayName(customer.lastName ? [customer.firstName, customer.lastName].join(" ") : customer.email)
        } else setDisplayName(null)
    }, [customer])

    if (customer === null && !loaded) {
        return <LoadingSpinner />
    } else if (customer === null) {
        return <div>Customer not found.</div>
    }

    const bankDetailsCallback = () => {
        for (let i = 0; i < 3; i++) {
            // fetch card data 3 times - 5, 10 and 15 seconds after the function is called
            setTimeout(() => loadCustomer(true), i * 5000);
        }
    }

    return <div>
        <div>
            <h1>{displayName}</h1>
            <p className={'body-reg'}>{customer.id}</p>
            <p className={'body-reg'}>{customer.email}</p>
            <span>{getState(customer.state)} ({customer.state})</span>
            <Tabs defaultActiveKey="summary" id="customer-profile-tabs" className="mb-3">
              <Tab eventKey="summary" title="Account detail">
                  {customerPromises ? <CustomerPromises promises={customerPromises} customer={customer} /> : <h5>No customer promises of payment found.</h5>}
                  <TablePagination
                    page={page}
                    changePageCallback={setPage}
                    count={count}
                    numberOfPages={pagesNumber}
                  />
              </Tab>
              <Tab eventKey="bank" title="Bank details">
                  <BankDetails customer={customer} callback={bankDetailsCallback} />
              </Tab>
              {/*<Tab eventKey="payments" title="Payments" disabled>*/}
              {/*  Payments (TODO: study the doc for details)*/}
              {/*</Tab>*/}
            </Tabs>

        </div>
    </div>
}

export default CustomerProfile;