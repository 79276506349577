import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import UserProfileForm, {pageStates} from "../UserProfileForm";
import {useAuthContext} from "../../lib/context/auth";


const UserProfileDetails = (props) => {
    const {user, fetchUser} = useAuthContext();
    const [canEdit, setCanEdit] = useState(false)
    const {currentUser} = props;

    useEffect(() => {
        // allow users to edit their own pages or any page if they are a superadmin
        if (currentUser.isSuperuser || user.id === currentUser.id) {
            setCanEdit(true)
        }
    }, [])

    const saveForm = () => {
        if (user === currentUser) fetchUser();
    }

    return (
        <div className={'w-25'}>
            <UserProfileForm user={{...currentUser}} callback={saveForm} />
        </div>
    )
}

export default UserProfileDetails;