import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import WrappedError from "../../components/WrappedError";
import {makeRequest} from "../../lib/api/request";
import "yup-phone";
import {useState} from "react";
import './index.css';
import {useNavigate} from "react-router-dom";

const RegistrationUserSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, "Too short"),
    lastName: Yup.string()
        .min(1, "Too short"),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Required'),
    phoneNumber: Yup.string()
        .phone("", false, 'Phone number is not valid')
})

const AgentCreate = (props) => {
    let navigate = useNavigate();
    const {callback} = props;
    const [errorMessage, setErrorMessage] = useState('');

    const saveForm = (values, actions) => {
        setErrorMessage("");
        makeRequest('/users/create/', 'POST', values).then(response => {
            if (response === 403) {
                navigate("/403")
            }
            setErrorMessage(response.error ? response.error : "");
            if (!response.id) {
                // sets errors from the response
                actions.setErrors(response)
            } else {
                callback();
            }
        })
    }
    return <div>
        <h3 className={'mx-3'}>Add new Agent</h3>
        <Formik
            enableReinitialize
            initialValues={{
                firstName: "",
                lastName: '',
                phoneNumber: '',
                email: '',
                password: '',
            }}
            validationSchema={RegistrationUserSchema}
            onSubmit={saveForm}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className={'d-flex flex-column child-flex mx-3'}>
                        <label htmlFor={'firstName'}>First Name</label>
                        <Field id={'firstName'} name="firstName" />
                        <WrappedError errors={errors} touched={touched} field={"firstName"} />

                        <label htmlFor={'lastName'}>Last Name</label>
                        <Field id={'lastName'} name="lastName" />
                        <WrappedError errors={errors} touched={touched} field={"lastName"} />

                        <label htmlFor={'phoneNumber'}>Phone number</label>
                        <Field id={'phoneNumber'} name="phoneNumber" />
                        <WrappedError errors={errors} touched={touched} field={"phoneNumber"} />

                        <label htmlFor={'email'}>Email</label>
                        <Field id={'email'} name="email" type={'email'}/>
                        <WrappedError errors={errors} touched={touched} field={"email"} />

                        <label htmlFor={'password'}>Password</label>
                        <Field id={'password'} name="password" type={'password'} />
                        <WrappedError errors={errors} touched={touched} field={"password"} />
                    </div>
                    { errorMessage ? <p className={'caption-reg text-error mx-3 mt-2'}>{errorMessage}</p> : null }
                    <div className={'mt-2 mx-3 d-flex flex-row justify-content-between'}>
                        <button className={'btn btn-secondary body-bold'} type={'button'} onClick={() => callback()}>Cancel</button>
                        <button type={'submit'} className={"btn btn-primary body-bold"}>Save</button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
}

export default AgentCreate;