import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import {useEffect, useState} from "react";
import {paymentStatusDisplay} from "../../lib/utils/promise";
import {formattedDate} from "../../lib/utils/fixdate";
import {Button, Modal, ModalBody} from "react-bootstrap";
import {makeRequest} from "../../lib/api/request";
import {useNavigate} from "react-router-dom";

const PaymentsHistoryList = (props) => {
  
    let navigate = useNavigate();

    const {payments, extraPayments, promise, reload, showAffectedPayments, affectedPayments, defaultPM} = props;
    const isManual = defaultPM.resourcetype === "Manual";
    const [loaded, setLoaded] = useState(false);
    const [allPayments, setAllPayments] = useState([]);
    const [affectedBy, setAffectedBy] = useState(null);
    const [showManual, setShowManual] = useState(false);
    const [showMoveBack, setShowMoveBack] = useState(false)
    // target for manual payment
    const [currentManual, setCurrentManual] = useState({});
    const [currentMoveBack, setCurrentMoveBack] = useState({})
    const [currentError, setCurrentError] = useState(null);

    useEffect(() => {
        let paymentsCombined = [
            ...payments.filter(p => p.status !== "scheduled"),
            ...extraPayments
        ].sort((a, b) => {

          return new Date(a?.paymentDate) - new Date(b?.paymentDate)
      
          // if (a.paymentDate && b.paymentDate) {
            // return new Date(a?.paymentDate) > new Date(b?.paymentDate) 
          // }

          // else if (a.scheduledPaymentDate && b.scheduledPaymentDate) {
          //   return new Date(a.scheduledPaymentDate) > new Date(b.scheduledPaymentDate)

          // } else if (a.scheduledPaymentDate && b.paymentDate) {
          //   return new Date(a.scheduledPaymentDate) > new Date(b.paymentDate)
          // } else if (a.paymentDate && b.scheduledPaymentDate) {
          //   return new Date(a.paymentDate) > new Date(b.scheduledPaymentDate)
          // }
          
          // return true
        }).reverse();

        setAllPayments(paymentsCombined)
    }, [payments, extraPayments])

    const handleSetAffected = (newAfftectedBy, affectedIds) => {
        // console.log(affectedBy, newAfftectedBy, affectedBy === newAfftectedBy)
        if (affectedBy === newAfftectedBy) {
            showAffectedPayments([]);
            setAffectedBy(null);
        } else {
            showAffectedPayments(affectedIds);
            setAffectedBy(newAfftectedBy);
        }
    }

    const performManual = () => {
        setShowManual(false);
        makeRequest(`promises/payments/${currentManual.id}/manual/`, "POST").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            reload();
        })
    }

    const performMoveBack = () => {
        setShowMoveBack(false);
        makeRequest(`promises/payments/${currentMoveBack.id}/move_back/`, "POST").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            reload();
        })
    }

    return <>
        {currentError && <p>Error: <span>{currentError.errorMessage}</span></p>}
        <Table>
        <thead>
            <tr>
                <td>Payment Date</td>
                <td>Scheduled Payment Date</td>
                <td>Amount Paid</td>
                <td>Payment Method</td>
                <td>Status</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/*<td>Repay</td>*/}
            {/*    TODO: add a button that shows errors?*/}
            </tr>
        </thead>
        <tbody>
            {allPayments.map((payment, index) => {
                let isAffected = affectedPayments && affectedPayments.includes(payment.id);
                const isMissed = payment.status === "missed" || payment.status === "failed";
                return <tr key={`all-payments-payment-${payment.id}`} className={isAffected ? "affected-payment": ""}>
                    <td>
                        {formattedDate(payment.paymentDate)}
                    </td>
                    <td>
                        {!payment.isExtra && payment.scheduledPaymentDate && formattedDate(payment.scheduledPaymentDate)}
                        {payment.isExtra && <Badge bg={'primary'} className={'ml-1'}>extra</Badge>}
                    </td>
                    {/*<td>${payment.amount}</td>*/}
                    <td>${payment.actualAmount}</td>
                    <td>{payment.paymentMethodDetails}</td>
                    <td>
                        { isManual ? <>
                            {isMissed && payment.paymentMethod === props.defaultPM.id ?
                                <Badge bg={"danger"}>{"Check not received"}</Badge>
                                :
                                paymentStatusDisplay(payment.status)
                            }
                        </> : <></>}
                        {!isManual && paymentStatusDisplay(payment.status)}
                    </td>
                    <td>
                        {payment.isExtra && payment.extraPaymentTargets.length > 0 && <Button
                            size={'sm'}
                            variant={affectedBy === payment.id ? "primary" : "secondary"}
                            onClick={() => {handleSetAffected(payment.id, payment.extraPaymentTargets.filter(t => t.id).map(t => t.id))}}
                        >Show Affected</Button>}
                    </td>
                    <td>
                        {isManual && isMissed &&
                            <Button
                                className={'btn btn-primary'}
                                onClick={() => {
                                    setCurrentManual(payment);
                                    setShowManual(true);
                                }}
                            >
                                Allocate Check Or Wire
                            </Button>
                        }
                    </td>
                    <td>
                        {isMissed && !payment.isExtra &&
                            <Button
                                className={'btn btn-primary'}
                                onClick={() => {
                                    setCurrentMoveBack(payment);
                                    setShowMoveBack(true);
                                }}
                            >
                                Move Back
                            </Button>
                        }
                    </td>
                    <td>
                        {payment.errors && <>
                            {payment.errors.map((error) => {
                                if (error.errorCode === "0" || payment.status !== "failed") return null;
                                return <Badge
                                    bg={currentError === error ? "warning" : "danger"}
                                    pill
                                    key={`error-${error.id}`}
                                    title={error.errorMessage}
                                    onClick={() => {
                                        if (currentError === error) {
                                            setCurrentError(null)
                                        } else {
                                            setCurrentError(error)
                                        }
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >{" ! "}</Badge>
                            })}
                        </>}
                    </td>
                </tr>
            })}
        </tbody>
    </Table>
    <Modal show={showManual} onHide={() => setShowManual(false)}>
        <Modal.Header closeButton>
            Allocate Check or Wire Payment
        </Modal.Header>
        <ModalBody>
            Are you sure you want to allocate a check or wire for {currentManual.actualAmount}?
        </ModalBody>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowManual(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={() => performManual()} disabled={currentManual.status === "deleted"}>
                Allocate
            </Button>
        </Modal.Footer>
    </Modal>
        <Modal show={showMoveBack} onHide={() => setShowMoveBack(false)}>
        <Modal.Header closeButton>
            Allocate Check or Wire Payment
        </Modal.Header>
        <ModalBody>
            Are you sure you want to move back a payment of ${currentMoveBack.actualAmount}?
        </ModalBody>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowManual(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={() => performMoveBack()} disabled={currentManual.status === "deleted"}>
                Move Back
            </Button>
        </Modal.Footer>
    </Modal>
    </>
}

export default PaymentsHistoryList;