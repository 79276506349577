import {useEffect, useState} from "react";
import {makeRequest} from "../../lib/api/request";
import LoadingSpinner from "../../components/LoadingSpinner";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import {Link, useNavigate} from "react-router-dom";
import {createRegularUser} from "../../lib/hooks/auth";
import TablePagination from "../../components/Pagination";
import {useAuthContext} from "../../lib/context/auth";
import {SystemDeleteIcon16, SystemEditIcon16} from "../../components/icons/SystemIcons";
import AgentCreate from "../AgentCreate";
import UserProfileForm from "../../components/UserProfileForm";
import {DebounceInput} from "react-debounce-input";

// TODO: add search
const AgentsList = () => {
    let navigate = useNavigate();
    const {user} = useAuthContext();
    const [agentsList, setAgentsList] = useState([])
    const [loaded, setLoaded] = useState(false);

    // const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pagesNumber, setPagesNumber] = useState(0);
    const [search, setSearch] = useState("")

    // handle modals
    const [showAddModal, setShowAddModals] = useState(false);
    const [showEditModal, setShowEditModals] = useState(false);
    const [showDeleteModal, setShowDeleteModals] = useState(false);
    const [editedUser, setEditedUser] = useState(null);

    const handleEdit = (agent) => {
        setEditedUser(agent);
        setShowEditModals(true);
    }

    useEffect(() => {
        loadUsers();
    }, [page, showAddModal, showEditModal, showDeleteModal,search])

    const getUrl = () => {
        let url = `users/?page=${page}`

        if (search) url = `${url}&search=${encodeURIComponent(search)}`;

        return url
    }

    const loadUsers = () => {
        setLoaded(false)
        makeRequest(getUrl(), "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            if (response.status === 400 || response.detail === "Invalid page.") {
                setCount(0)
                setPagesNumber(1)
                setAgentsList([])
                setPage(1)
                setLoaded(true)
                return
            }
            setCount(response.count);
            setPagesNumber(response.pages);
            setAgentsList(response.results.map(rawAgent => createRegularUser(rawAgent)));
            setLoaded(true);
        })
    }

    if (!loaded) return <LoadingSpinner />
    return (<div>
        <h1>Agents</h1>
        <div className={'d-flex flex-row justify-content-between my-4'}>
            <DebounceInput
              placeholder={"Search"}
              minLength={2}
              debounceTimeout={300}
              value={search}
              onChange={e => setSearch(e.target.value)}
              autoFocus={search !== ""}
            />
            { user.isSuperuser ? <div>
                <button className={'btn btn-primary body-bold'} onClick={() => setShowAddModals(true)}>Add</button>
            </div> : null}
        </div>

        {/* MODALS */}
        <Modal show={showAddModal} onHide={() => setShowAddModals(false)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <AgentCreate callback={setShowAddModals} />
            </Modal.Body>
        </Modal>

        <Modal show={showEditModal} onHide={() => setShowEditModals(false)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                { editedUser && <UserProfileForm user={editedUser} callback={() => setShowEditModals(false)} isModal={true}/>}
            </Modal.Body>
        </Modal>

        {/* LIST */}
        <Table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th className={'w-50'}> </th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            { count === 0 && <tr className={'d-flex flex-row'}>No results</tr>}
            {agentsList.map(agent => {
                return (
                    <tr key={agent.id}>
                        <td>{agent.displayName}</td>
                        <td>{agent.email}</td>
                        <td className={'w-50'}> </td>
                        <td>
                            <div>
                                {/* TODO: remake into modal triggers*/}
                                <span className={'link-icon'} onClick={() => handleEdit(agent)} style={{marginRight: '1rem'}}>
                                    <SystemEditIcon16 />
                                </span>
                                <Link disabled to={''} className={'link-icon-fill'}>
                                    <SystemDeleteIcon16 />
                                </Link>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </tbody>
        </Table>
        <TablePagination
            page={page}
            changePageCallback={setPage}
            count={count}
            numberOfPages={pagesNumber}
        />
    </div>)
}

export default AgentsList;