import React, { useEffect } from "react";
import {NavDropdown} from "react-bootstrap";
import {useAuthContext} from "../../lib/context/auth";
import {SystemArrowDownIcon24} from "../icons/SystemIcons";

export const UserDropdown = () => {

  const {logout, user} = useAuthContext();
  const dropdownTitle = (<>{user.displayName} <SystemArrowDownIcon24 /></>);
  
  let timer;
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logout()
    }, 1000 * 60 * 15); // 15 min
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  return (
    <NavDropdown title={dropdownTitle} id="user-details-dropdown">
      <NavDropdown.Item href={'/profile'}>Profile Settings</NavDropdown.Item>
      <NavDropdown.Item onClick={() => logout()}>Log Out</NavDropdown.Item>
    </NavDropdown>
  )
}