import Table from "react-bootstrap/Table";
import {makeRequest} from "../../lib/api/request";
import {useState} from "react";
import SinglePayment from "./SinglePayment";
import {useNavigate} from "react-router-dom";

const PaymentsList = (props) => {
    let navigate = useNavigate();
    const {payments, reload, useManual, otp, promise, affectedPayments} = props;
    const [loaded, setLoaded] = useState(false);

    const makeManualPayment = (targetId) => {
        setLoaded(false);
        makeRequest(`promises/payments/${targetId}/manual/`, "POST").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            setLoaded(true);
            reload();
        })
    }

    return <>
        <Table>
        <thead>
            <tr>
                <td>Date</td>
                {/* TODO: split amount into plannedAmount and actualAmount*/}
                <td>Initial Amount</td>
                <td>Actual Amount</td>
                <td>Status</td>
                <td></td>
            </tr>
        </thead>
        <tbody>
            {payments.filter(payment => payment.status === 'scheduled').map((payment, index) => {
                let isAffected = affectedPayments && affectedPayments.includes(payment.id);
                return <SinglePayment
                    key={`single-payment-${payment.id}`}
                    isAffected={isAffected}
                    payment={payment}
                    promiseDeleted={promise.status === "deleted"}
                    index={index}
                    manual={makeManualPayment}
                    useManual={useManual}
                    otp={otp}
                />;
            })}
        </tbody>
    </Table>
    </>
}

export default PaymentsList;