import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Field, Form, Formik} from "formik";
import WrappedError from "../../components/WrappedError";
import LoadingSpinner from "../../components/LoadingSpinner";
import * as Yup from "yup";
import {useState} from "react";
import {makeRequest} from "../../lib/api/request";

const ACHSchema = Yup.object().shape({
    nameOnCheck: Yup.string()
        .required("Required"),
    achAccountNumber: Yup.string()
        .matches(/^\d*$/, "Only numbers allowed")
        .required('Required'),
    achRoutingNumber: Yup.string()
        .matches(/^\d{9}$/, "Must be 9 digits")
        .required('Required'),
    achAccountType: Yup.string()
        .matches(/^checking|savings$/, "Unsupported value")
        .required('Required'),
    authorization: Yup.string()
        .matches(/^web|verbal|written$/, "Unsupported value")
        .required('Required'),
})

const ACHModal = ({show, handleClose, customer, customerCallback}) => {
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values, actions) => {
        setLoading(true);
        makeRequest(`/customers/${customer.id}/payment_methods/add/ach2/`, 'POST', values)
        .then(response => {
            if (!response.success) {
                setFormError(response.message || "Error adding ACH.")
            } else {
                setFormError("")
                setTimeout(() => setLoading(false), 500);
                setTimeout(() => handleClose(), 550);
                setTimeout(() => customerCallback(), 550);
            }
            setLoading(false);
        })
        .catch(err => console.log('err', err))
    }

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Formik
                enableReinitialize
                initialValues={{
                    nameOnCheck: "",
                    achAccountNumber: "",
                    achRoutingNumber: "",
                    achAccountType: "checking",
                    authorization: "web"
                }}
                validationSchema={ACHSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <h3>ACH Form</h3>
                        <div className={'col-md mb-3'}>
                            <label htmlFor={'nameOnCheck'}>Name on check</label><br />
                            <Field id={'nameOnCheck'} name="nameOnCheck" type={'text'} className="form-control" />
                            <WrappedError errors={errors} touched={touched} field={"nameOnCheck"} />
                        </div>
                        <div className={'col-md mb-3'}>
                            <label htmlFor={'achAccountNumber'}>Account number</label><br />
                            <Field id={'achAccountNumber'} name="achAccountNumber" type={'text'} className="form-control" />
                            <WrappedError errors={errors} touched={touched} field={"achAccountNumber"} />
                        </div>
                        <div className={'col-md mb-3'}>
                            <label htmlFor={'achRoutingNumber'}>Routing number</label><br />
                            <Field id={'achRoutingNumber'} name="achRoutingNumber" type={'text'} className="form-control" />
                            <WrappedError errors={errors} touched={touched} field={"achRoutingNumber"} />
                        </div>
                        <div className={'col-md mb-3'}>
                            <label htmlFor={'achAccountType'}>Account type</label><br />
                            <Field as={"select"} className="form-control" id={"achAccountType"} name={"achAccountType"}>
                                <option value={"checking"}>Checking</option>
                                <option value={"savings"}>Savings</option>
                            </Field>
                            <WrappedError errors={errors} touched={touched} field={"nameOnCheck"} />
                        </div>
                        <div className={'col-md mb-3'}>
                            <label htmlFor={'authorization'}>Authorization</label><br />
                            <Field id={'authorization'} name="authorization" as={'select'} className="form-control">
                                <option value={"web"}>Web</option>
                                <option value={"verbal"}>Verbal</option>
                                <option value={"written"}>Written</option>

                            </Field>
                            <WrappedError errors={errors} touched={touched} field={"authorization"} />
                        </div>

                        { formError ? <div className="alert alert-danger">{formError}</div> : null }
                        <div className={'col-md mb-3'}>
                            <Button type={'submit'} disabled={loading}>Submit</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal.Body>
      </Modal>
}

export default ACHModal;