import {Navbar, Container, Nav} from "react-bootstrap";
import {UserDropdown} from '../UserDropdown'
import {useAuthContext} from "../../lib/context/auth";
import React, {useEffect, useState} from "react";
import './index.css';
import { useLocation } from "react-router-dom";
import {TENANTS, useTenantContext} from "../../lib/context/tenantContext";

const NavBar = () => {
    const {user} = useAuthContext();

    const {tenant} = useTenantContext();

    const location = useLocation();

    const getActivePage = () => {
        let pathName = location.pathname;
        if (pathName.startsWith('/promises')) {
            return "promises"
        } else if (pathName.startsWith('/customers')) {
            return "customers"
        } else if (pathName.startsWith('/agents')) {
            return "agents"
        }
        return null
    }

    const activePage = getActivePage();

    return <Navbar className={'navbar'}>
    <Container fluid className={'nav-container'}>
        <Navbar.Brand href="/" className={''}>
            <h3>Pathway</h3>
            {tenant === TENANTS.consuegra && <span className={'tenant-identifier'}>Consuegra</span>}
            {tenant === TENANTS.tromberg && <span className={'tenant-identifier'}>Tromberg</span>}
            {tenant === TENANTS.ncls && <span className={'tenant-identifier'}>NCLS</span>}
            {tenant === TENANTS.tidewater && <span className={'tenant-identifier'}>Tidewater</span>}
            {tenant === TENANTS.javitch && <span className={'tenant-identifier'}>Javitch</span>}
        </Navbar.Brand>
        <Nav className="me-auto">
            { user.isSuperuser || user.isStaff ? <Nav.Link
                href="/promises"
                className={activePage === "promises" ? "body-bold nav-active": "body-reg"}
            >Promises</Nav.Link> : null }
            { user.isSuperuser ? <Nav.Link
                href="/agents"
                className={activePage === "agents" ? "body-bold nav-active": "body-reg"}
            >Agents</Nav.Link> : null }
            { user.isSuperuser || user.isStaff ? <Nav.Link
                href="/customers"
                className={activePage === "customers" ? "body-bold nav-active": "body-reg"}
            >Customers</Nav.Link> : null }
        </Nav>
        <UserDropdown />
    </Container>
  </Navbar>
}

export default NavBar;