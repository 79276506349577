import {useAuthContext} from "../../lib/context/auth";
import {useEffect, useState} from "react";
import {makeRequest} from "../../lib/api/request";
import {createRegularUser} from "../../lib/hooks/auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Link, useNavigate} from "react-router-dom";
import Table from "react-bootstrap/Table";
import TablePagination from "../../components/Pagination";
import {DebounceInput} from "react-debounce-input";


const CustomerList = () => {
    const {user} = useAuthContext();
    let navigate = useNavigate();
    const [customerList, setCustomerList] = useState([])
    const [loaded, setLoaded] = useState(false);

    // const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pagesNumber, setPagesNumber] = useState(0);
    const [search, setSearch] = useState("");

    useEffect(() => {
        loadCustomers();
    }, [page, search])

    const getUrl = () => {
        let url = `customers/?page=${page}`;

        if (search) url = `${url}&search=${encodeURIComponent(search)}`;

        return url
    }

    const loadCustomers = () => {
        setLoaded(false)
        makeRequest(getUrl(), "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            if (response.status === 400 || response.detail === "Invalid page.") {
                setCount(0)
                setPagesNumber(1)
                setCustomerList([])
                setPage(1)
                setLoaded(true)
                return
            }
            setCount(response.count);
            setPagesNumber(response.pages);
            setCustomerList(response.results.map(rawAgent => createRegularUser(rawAgent)));
            setLoaded(true);
        })
    }

    if (!loaded) return <LoadingSpinner />
    return (<div>
        <div className={'d-flex flex-row justify-content-between my-4'}>
            <DebounceInput
              placeholder={"Search"}
              minLength={2}
              debounceTimeout={600}
              value={search}
              onChange={e => setSearch(e.target.value)}
              autoFocus={search !== ""}
            />
        </div>
        <Table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Managed by</th>
                <th className={'w-50'}> </th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
        { count === 0 && <tr className={'d-flex flex-row'}>No results</tr>}
        {customerList.map(customer => {
            return <tr key={customer.id}>
                    <td>{customer.displayName}</td>
                    <td>{customer.email}</td>
                    <td>Not implemented</td>
                    <td className={'w-50'}> </td>
                    <td>
                        <div>
                            <Link to={`/customers/${customer.id}/`} style={{marginRight: '1rem'}}>
                                <button className={'btn body-bold btn-outline-primary'}>View</button>
                            </Link>
                            {/*<Link disabled to={''}>*/}
                            {/*    <button className={'btn btn-outline-danger'} disabled>Delete</button>*/}
                            {/*</Link>*/}
                        </div>
                    </td>
                </tr>
        })}
        </tbody>
        </Table>
        <TablePagination
            page={page}
            changePageCallback={setPage}
            count={count}
            numberOfPages={pagesNumber}
        />
    </div>)
}

export default CustomerList;