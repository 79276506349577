import {makeRequest} from "../../lib/api/request";
import {useEffect, useState} from "react";
import dropin from "braintree-web-drop-in"
import {Button} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";


const PayPal = (props) =>  {
    const [params, setParams] = useSearchParams()
    const id = params.get('id')

    const [braintreeInstance, setBraintreeInstance] = useState(undefined)
    const [clientToken, setClientToken] = useState("")
    const [success, setSuccess] = useState(false)

    const loadClientToken = () => {
        makeRequest(`/paypal/client_token?user_id=${id}`).then((r) => {
          console.log('braintree response', r)
          setClientToken(r["clientToken"])
        })
    }

    const processPaymentNonce = (payload) => {
        try {
            makeRequest("/paypal/create/", "POST", {
                "userId": id,
                "paymentMethodNonce": payload.nonce,
                "email": payload.details.email,
            }).then((r) => {
                setSuccess(true)
                window.parent.postMessage("PAYMENT_SUCCESS", "*")
            })
        } catch (error) {
            console.log('error when submitting paymentMethodNonce:', error)
        }
    }

    useEffect(() => {
        if (id) loadClientToken()
    }, []);

    useEffect(() => {
        if (!clientToken) return
        const initializeBraintree = () => dropin.create({
            // insert your tokenization key or client token here
            authorization: clientToken,
            container: '#braintree-drop-in-div',
            intent: "tokenize",
            paypal: {
                flow: "vault",
                amount: 0,
                currency: "USD",
            },
            card: false,
            hiddenVaultedPaymentMethodTypes: ["card", "paypalCredit"],
        },
        // callback fn
        function (error, instance) {
            if (error)
                console.error('Error while initializing braintree',  error)
            else
                setBraintreeInstance(instance);
        });

        if (braintreeInstance) {
            braintreeInstance
                .teardown()
                .then(() => {
                    initializeBraintree();
                });
        } else {
            initializeBraintree();
        }
    }, [clientToken])

    return (<>
        { id && <>
            {!success ? <div>
                <div
                    id={"braintree-drop-in-div"}
                />

                <Button
                    className={"braintreePayButton"}
                    type="primary"
                    disabled={!braintreeInstance}
                    onClick={() => {
                        if (braintreeInstance) {
                            braintreeInstance.requestPaymentMethod(
                                (error, payload) => {
                                    console.log('full payload:', payload)
                                    if (error) {
                                        console.error('requestPaymentMethod error:', error);
                                    } else {
                                       processPaymentNonce(payload)
                                    }
                                });
                        }
                    }}
                >
                    Connect
                </Button>
            </div>: <div>
                <h2>Payment method successfully added.</h2>
            </div>}
        </>}
            {!id && <div>
                <h2>No user id specified</h2>
            </div>}
        </>
    )
}

export default PayPal