import {Badge, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {displayPromiseType, displayTermType, statusDisplay} from "../../lib/utils/promise";
import {createRegularUser} from "../../lib/hooks/auth";
import DelinquentBadge from "../../components/DelinquentBadge";
import {useEffect} from "react";

const CustomerPromises = (props) => {
    const {promises, customer} = props;
    return <Table>
        <thead>
            <tr>
                <td>ID</td>
                <td>Data ID</td>
                <td>Name</td>
                <td>Start</td>
                <td>End</td>
                <td>Type</td>
                <td>Loan Amount</td>
                <td>Status</td>
            </tr>
        </thead>
        <tbody>
            {promises && promises.map(promise => {
                let regularOwner = createRegularUser(customer ? customer : promise.owner)
                return <tr key={promise.id}>
                    <td>
                        { promise.owner && <Link to={`/promises/${customer ? customer.id : promise.owner.id}/${promise.id}`}>
                            {promise.id}
                            {promise.isDelinquent && <DelinquentBadge />}
                        </Link>}
                    </td>
                    <td>{promise.dataId}</td>
                    <td>{regularOwner.displayName}</td>
                    <td>{promise.promiseType !== "OTP" && promise.startDate}</td>
                    <td>{promise.promiseType !== "OTP" && promise.lastPaymentDate}</td>
                    <td>{promise.isOpenEnded ? "Open Plan" : displayPromiseType(promise.promiseType)}</td>
                    <td>{promise.loanAmount} USD</td>
                    <td>{statusDisplay(promise.status)}</td>
                </tr>
            })}
            {!promises && <tr>No results</tr>}
        </tbody>
    </Table>
}

export default CustomerPromises;