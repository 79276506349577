import {useEffect, useState} from "react";
import './style.scss';
import {Button} from "react-bootstrap";
import { makeRequest } from "../../lib/api/request";
import {useAuthContext} from "../../lib/context/auth";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import WrappedError from "../../components/WrappedError";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Required'),
})

// TODO: add formik & yup validation
const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [retrievedToken, setRetrievedToken] = useState("");
    const {login} = useAuthContext();
    const navigate = useNavigate();

    const handleSubmit = (values, actions) => {
        setLoading(true)
        setFormError("")
        makeRequest("users/login/", 'POST', values)
        .then(response => {
            if (response === 403) {
                navigate("/403")
            }
            if (!response.success) {
                setFormError(response.message)
            } else {
                setFormError("")
                setRetrievedToken(response.token);
                setTimeout(() => navigate("/"), 250);
            }
            setLoading(false);
        })
        .catch(err => console.log('err', err))
    }

    useEffect(() => {
        if (retrievedToken) login(retrievedToken)
    }, [retrievedToken])

    return <div className={'d-flex flex-row justify-content-center align-items-center vh-100 pb-3'}>
        <Formik
            enableReinitialize
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched }) => (
                <Form>
                    <h3>Login Form</h3>
                    <div className={'col-md mb-3'}>
                        <label htmlFor={'email'}>Email</label><br />
                        <Field id={'email'} name="email" type={'email'}/>
                        <WrappedError errors={errors} touched={touched} field={"email"} />
                    </div>
                    <div className={'col-md mb-3'}>
                        <label htmlFor={'password'}>Password</label><br />
                        <Field id={'password'} name="password" type={'password'} />
                        <WrappedError errors={errors} touched={touched} field={"password"} />
                    </div>
                    { formError ? <div className="alert alert-danger">{formError}</div> : null }
                    {!loading?
                        <div className={'col-md mb-3'}>
                            <Button type={'submit'}>Log In</Button>
                        </div> : <LoadingSpinner />
                    }
                </Form>
            )}
        </Formik>
    </div>
};

export default LoginPage;