import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {makeRequest} from "../../lib/api/request";
import LoadingSpinner from "../../components/LoadingSpinner";
import {displayPromiseType, displayTermType, statusDisplay} from "../../lib/utils/promise";
import Table from "react-bootstrap/Table";
import {createRegularUser} from "../../lib/hooks/auth";
import PaymentsList from "../../components/PaymentsList";
import {Button, Modal, ModalBody, Tab, Tabs} from "react-bootstrap";
import PaymentsHistoryList from "../../components/PaymentsHistoryList";
import PaymentMethodPreview from "../../components/PaymentMethodPreview";
import EditPaymentsModal from "../../components/EditPaymentsModal";
import DelinquentBadge from "../../components/DelinquentBadge";
import PromiseHistoryEntry from "../../components/PromiseHistoryEntry";
import PaymentPromiseHistoryEntry from "../../components/PaymentPromiseHistoryEntry";
import ExtraPaymentsList from "../../components/ExtraPaymentsList";
import './index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {useAuthContext} from "../../lib/context/auth";

const PromiseDetails = (props) => {
    let { id, userid } = useParams();
    let navigate = useNavigate();
    const [promise, setPromise] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loadedHistory, setLoadedHistory] = useState(false);
    const [loadedForm, setLoadedForm] = useState(true);
    const [customer, setCustomer] = useState(null)
    const [extraAmount, setExtraAmount] = useState(0);
    const [amountError, setAmountError] = useState(false);
    const [extraError, setExtraError] = useState(null);
    const [paymentsHistory, setPaymentsHistory] = useState([]);
    const [affectedPayments, setAffectedPayments] = useState([]);
    const [defaultPM, setDefaultPM] = useState({});

    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [showEdit, setShowEdit] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    useEffect(() => {
        makeRequest(`/customers/${userid}/default_payment_method/?loan_id=${id}`, 'GET').then(response => {
            if (response.detail) {
                console.log('error when getting a customer')
                // setError(true)
            } else {
                setDefaultPM(response)
            }
        })
    }, [])
    const loadPromise = () => {
        setLoaded(false);
        makeRequest(`promises/${userid}/${id}/`, "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            setPromise(response);
            setCustomer(createRegularUser(response.owner));
            setLoaded(true);
        })
    }

    const loadPromisePaymentsHistory = () => {
        console.log('loading history')
        setPaymentsHistory([]);
        setLoadedHistory(false);
        makeRequest(`promises/${userid}/${id}/history/`, "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            console.log('loaded history')
            setPaymentsHistory(response.paymentsHistory);
            setLoadedHistory(true)
        })
    }

    useEffect(() => {
        if (promise && !paymentsHistory.length && !loadedHistory) loadPromisePaymentsHistory()
    }, [promise])

    const makeExtraPayment = () => {
        setExtraError(null);
        setLoadedForm(false);
        makeRequest(`promises/${userid}/${id}/extra/`, "POST", {"amount": extraAmount}).then(response => {
            setLoadedForm(true);
            if (response.success) {
                setShow(false);
                loadPromise();
            } else {
                setExtraError(response.message);
            }
        })
    }

    const performDeletion = () => {
        makeRequest(`promises/${userid}/${id}/delete/`, "POST", {}).then(response => {
            setLoadedForm(true);
            setShowDelete(false);
            if (response.success) {
                loadPromise();
            } else {
                console.log("Error while deleting the promise")
            }
        })
    }

    const setValidAmount = (rawValue, setter) => {
        if (rawValue !== '0' && rawValue.startsWith('0') && !rawValue.startsWith('0.')) {
            rawValue = parseInt(rawValue).toString()
        }
        setter(rawValue.match(/(^\d+\.?\d{0,2}$)|(^$)/)[0])
    }

  useEffect(() => {
    loadPromise();
  }, []);

    useEffect(() => {
        setAmountError(extraAmount < 0.1 || extraAmount > parseFloat(promise.debtRemainder))
    }, [extraAmount])

    const showAffectedPayments = (affectedIds) => {
        setAffectedPayments(affectedIds);
    }

    if (!loaded) return <LoadingSpinner />
    return <>
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Header closeButton>
                Delete Established Debt
            </Modal.Header>
            <ModalBody>
                Are you sure you want to delete this established debt?
            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDelete(false)}>
                    Close
                </Button>
                <Button variant="danger" onClick={() => performDeletion()} disabled={promise.status === "deleted"}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <div className={'d-flex flex-row align-items-center mb-1'}>
            <h5>Promise #{promise.id} {promise.isDelinquent && <DelinquentBadge />}</h5>
            <Button variant="danger" onClick={() => setShowDelete(true)} className={'ml-2'} disabled={promise.status === "deleted"}>Delete</Button>
        </div>
        <Table>
            <thead>
                <tr>
                    <td>Status</td>
                    <td>Loan ID</td>
                    <td>Customer</td>
                    <td>Data ID</td>
                    <td>Loan Type</td>
                    <td>Amount</td>
                    <td>Debt Amount</td>
                    <td>Term</td>
                </tr>
            </thead>
            <tbody>
                    <tr>
                        <td>{statusDisplay(promise.status)}</td>
                        <td>{promise.id} {promise.isDelinquent && <DelinquentBadge />}</td>
                        <td><Link to={`/customers/${customer.id}/`}>{customer.displayName}</Link></td>
                        <td>{promise.dataId}</td>
                        <td>{promise.isOpenEnded ? "Open Plan" : displayPromiseType(promise.promiseType)}</td>
                        <td>${promise.loanAmount}</td>
                        <td>${promise.debtAmount}</td>
                        <td>
                            {promise.promiseType === 'PP' && <>{promise.term} {displayTermType(promise.termType)}</>}
                            {promise.promiseType === 'OTP' && <>OTP</>}
                        </td>
                    </tr>
            </tbody>
        </Table>

        <Tabs defaultActiveKey="transactions" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="pm" title="Payment Method">
                <>Current default payment method: <PaymentMethodPreview pm={defaultPM} /></>
            </Tab>
            <Tab eventKey="payments" title="Payment Schedule">
                {promise.payments ? <div>
                    <div className={'d-flex flex-row justify-content-between'}>
                        <div>
                            <input placeholder={'Search'} hidden/>
                        </div>
                        <div>
                            <button className={'btn btn-primary mb-2 mr-1'} onClick={() => handleShow()} disabled={["deleted", "completed", "paid_off"].includes(promise.status) || promise.promiseType === 'OTP'}>
                                Make an extra payment
                            </button>
                            <button className={'btn btn-primary mb-2'} onClick={() => handleShowEdit()}>Edit</button>
                        </div>
                    </div>
                    <PaymentsList promise={promise} payments={promise.payments} reload={loadPromise} useManual={true} otp={promise.promiseType === 'OTP'} />
                </div> : <p>No payments found</p>}
                {/* Manual Extra Payment Modal*/}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class={'d-flex flex-column'}>
                            <div>
                                <p className={'body-bold'}>Payment method to be used:</p>
                                <PaymentMethodPreview pm={defaultPM} />
                            </div>
                            <label for={'manual-extra-amount'}>Enter amount:</label>
                            <input
                                key={'extra-amount-input'}
                                id={'manual-extra-amount'}
                                type={'number'}
                                max={promise.debtRemainder}
                                value={extraAmount}
                                onChange={(e) => setValidAmount(e.target.value, setExtraAmount)}
                            />
                            { amountError && <div style={{color: "#dc3545", fontSize: "0.85rem"}}>
                                Extra amount must be between $0.1 and ${promise.debtRemainder}.</div>}
                            { extraError && <div style={{color: "#dc3545", fontSize: "0.85rem"}}>{extraError}</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>
                            Close
                        </Button>
                        {promise.status !== "deleted" ?
                        <Button variant="primary" onClick={() => makeExtraPayment()} disabled={amountError || extraError || !loadedForm }>
                            Apply Extra Payment
                        </Button> : <Button variant={'primary'} disabled>Apply Extra Payment</Button>}
                    </Modal.Footer>
                </Modal>

                {/*  Edit Payments Modal  */}
                <EditPaymentsModal promise={promise} show={showEdit} handleClose={handleCloseEdit} callback={loadPromise} />

            </Tab>
            <Tab eventKey="transactions" title="Transactions">
                {promise.payments || promise.extraPayments ? <div className={'d-flex flex-row'}>
                    <div className={'w-100'} id={'transactions-left'}>
                        <h3>Past</h3>
                        <div className={'scrollable-list'}>
                            <PaymentsHistoryList
                                payments={promise.payments}
                                extraPayments={promise.extraPayments}
                                promise={promise}
                                reload={loadPromise}
                                showAffectedPayments={showAffectedPayments}
                                affectedPayments={affectedPayments}
                                defaultPM={defaultPM}
                            />
                        </div>
                    </div>
                    {/*{promise.extraPayments && <div className={'ml-1 w-100'}>*/}
                    {/*    <h3>Extra payments</h3>*/}
                    {/*    <div className={'scrollable-list'}>*/}
                    {/*        <ExtraPaymentsList payments={promise.extraPayments} promise={promise} className={'scrollable-list'} />*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    <div className={'ml-1 w-100'}>
                        <h3>Scheduled</h3>
                        <div className={'scrollable-list'}>
                            <PaymentsList
                                promise={promise}
                                payments={promise.payments}
                                reload={loadPromise}
                                className={'scrollable-list'}
                                useManual={false}
                                otp={promise.promiseType === 'OTP'}
                                affectedPayments={affectedPayments}
                            />
                        </div>
                    </div>
                </div> : <p>No paymnents found</p>}
            </Tab>
            <Tab eventKey="history" title="History">
                {promise ? <div id={'transactions-right'}>
                    <Table>
                            <thead>
                                <tr>
                                    <th>Payment ID</th>
                                    <th>Is extra?</th>
                                    <th>Change ID</th>
                                    <th>Date of change</th>
                                    <th>Date of scheduled payment</th>
                                    <th>Date of actual payment</th>
                                    <th>Change Type</th>
                                    <th>Inital amount</th>
                                    <th>Actual amount</th>
                                    <th>Status</th>
                                    <th>Field Changes</th>
                                </tr>
                            </thead>
                        <tbody>
                            {loadedHistory ? paymentsHistory.map((entry, index) => {
                                // console.log('history entry', entry)
                                return <PaymentPromiseHistoryEntry paymentHistory={entry} index key={`payment-history-${entry.id}-${index}`} />
                            }) : <>
                                {!loadedHistory && <LoadingSpinner />}
                                {loadedHistory && !paymentsHistory.length && <trow><p>No changes yet.</p></trow>}
                                </>
                            }
                        </tbody>
                    </Table>
                </div> : <LoadingSpinner />
                }
            </Tab>
        </Tabs>
    </>
}

export default PromiseDetails;