import Table from "react-bootstrap/Table";
import {useEffect, useLayoutEffect, useState} from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {makeRequest} from "../../lib/api/request";
import {useAuthContext} from "../../lib/context/auth";
import LoadingSpinner from "../LoadingSpinner";
import WrappedError from "../WrappedError";
import {useNavigate} from "react-router-dom";

export const pageStates = {
    view: "VIEW",
    edit: "EDIT",
}

const UserSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, "Too short"),
    lastName: Yup.string()
        .min(1, "Too short"),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
})

const UserProfileForm = (props) => {
    let navigate = useNavigate();
    const {user, callback, isModal} = props;
    const {fetchUser} = useAuthContext();
    const [userValues, setUserValues] = useState({});
    const [loaded, setLoaded] = useState(false);

    // load initial values
    useLayoutEffect(() => {
        makeRequest(`users/${user.id}/`, "GET").then(response => {
            if (response === 403) {
                navigate("/403")
            }
            setUserValues(response);
            setLoaded(true);
        })
    }, [])

    const saveForm = async (values) => {
        delete values["displayName"]
        delete values["id"]
        // TODO: resolve permissions and cleanup the request
        // delete values["isSuperuser"]
        makeRequest(`users/${user.id}/`, "PATCH", values).then(response => {
            if (response === 403) {
                navigate("/403")
            }
            fetchUser();
            callback();
        })
        // TODO: add error handling
    }
    if (!loaded) {
        return <LoadingSpinner />
    }
    return (
        <div>
        {isModal && <h3 className={'mx-3'}>Edit Agent</h3>}
        <Formik
            enableReinitialize
            initialValues={{
               ...userValues,
               password: '',
           }}
           validationSchema={UserSchema}
           onSubmit={saveForm}
        >
            {({ errors, touched }) => (
            <Form>
                <div className={'d-flex flex-column child-flex mx-3'}>
                    <label htmlFor={'firstNameEdit'}>First Name</label>
                    <Field name="firstName" id={'firstNameEdit'} />
                    <WrappedError errors={errors} touched={touched} field={"firstName"} />

                    <label htmlFor={'lastNameEdit'}>Last Name</label>
                    <Field name="lastName" id={"lastNameEdit"}/>
                    <WrappedError errors={errors} touched={touched} field={"lastName"} />

                    <label htmlFor={'emailEdit'}>Email</label>
                    <Field name="email" id={'emailEdit'}/>
                    <WrappedError errors={errors} touched={touched} field={"email"} />

                    <label htmlFor={'passwordEdit'}>Password</label>
                    <Field name="password" type={"password"} id={'passwordEdit'} />
                    <WrappedError errors={errors} touched={touched} field={"password"} />

                    <label htmlFor={'phoneNumberEdit'}>Phone Number</label>
                    <Field name="phoneNumber" id={"phoneNumberEdit"} />
                    <WrappedError errors={errors} touched={touched} field={"phoneNumber"} />

                    <div className={'mt-2 mx-1 d-flex flex-row justify-content-between'}>
                        {isModal && <button
                            className={'btn btn-secondary body-bold'}
                            type={'button'} onClick={() => callback()}
                        >Cancel</button>
                        }
                        <button type={'submit'} className={"btn btn-primary body-bold"}>Save Changes</button>
                    </div>
                </div>
            </Form>)}
            </Formik>
        </div>
    )
}

export default UserProfileForm;