const PaymentMethodPreview = (props) => {
    const {pm} = props;
    switch (pm.resourcetype) {
        case "Manual":
            return <p>Manual payment</p>;
        case "Card":
            return <p>{pm.cardBrand} xxxx-{pm.last4}</p>
        case "ACH":
            return <p>ACH xxxx-{pm.last4} {pm.bankName ? `(${pm.bankName})` : ""}</p>
        default:
            return <p>{pm.resourcetype}</p>
    }
}

export default PaymentMethodPreview;